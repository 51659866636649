import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import { FaHandsHelping } from '@react-icons/all-files/fa/FaHandsHelping';
import { FaUserShield } from '@react-icons/all-files/fa/FaUserShield';
import { BiChat } from '@react-icons/all-files/bi/BiChat';

const SupportCardStyled = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  min-width: 100%;
  min-height: 540px;
  border-radius: 8px;
  color: var(--white);
  text-align: center;
  overflow: hidden;
  box-shadow: var(--boxShadow);
  transition: 500ms ease-in-out;
  &.open {
    background-size: 4000%;
    background: var(--black);
    background-position: center top;
  }
  .overlay {
    display: grid;
    align-items: center;
    justify-items: center;
    height: 100%;
    width: 100%;
    padding: 7rem 5rem;
    border-radius: 8px;
    border: 1px solid #000;
    background: #000;
    a {
      text-decoration: none;
      color: var(--watermelon);
      font-weight: 600;
      cursor: pointer;
      padding-bottom: 3px;
    }
    &[data-color-scheme='white'] {
      background: #fff;
      color: #000;
    }
    &[data-color-scheme='watermelon'] {
      background: var(--watermelon);
      color: #000;
      a {
        color: inherit;
        border-bottom: 1px solid #000;
      }
    }
    &[data-color-scheme='black-trans'] {
      background: linear-gradient(to bottom, black, transparent);
      color: #fff;
      align-items: flex-start;
    }
    &[data-color-scheme='trans-black'] {
      background: linear-gradient(to bottom, transparent, black);
      color: #fff;
      align-items: flex-end;
    }
    &[data-color-scheme='white-trans'] {
      background: linear-gradient(to bottom, white, transparent);
      color: #000;
      align-items: flex-start;
    }
    &[data-color-scheme='trans-white'] {
      background: linear-gradient(to bottom, transparent, white);
      color: #000;
      align-items: flex-end;
    }
  }
  .narrow {
    max-width: 300px;
    margin: 2rem auto 0;
  }
`;

export default function SupportCard(props) {
  const openBeacon = () => {
    Beacon('init', '79f086d6-c069-4024-a99a-3d545c16d0b8');
    Beacon('open');
  };
  return (
    <SupportCardStyled
      style={{
        backgroundImage: `url(${
          props.backgroundImage ? props.backgroundImage : ''
        })`,
      }}
    >
      <div className="overlay" data-color-scheme={props.colorScheme}>
        <div>
          {props.title.includes('Help Centre') ? (
            <h1>
              <FaHandsHelping />
            </h1>
          ) : (
            ''
          )}
          {props.title.includes('Privacy Policy') ? (
            <h1>
              <FaUserShield />
            </h1>
          ) : (
            ''
          )}
          {props.title.includes('Contact Us' || 'Contact Us') ? (
            <h1>
              <BiChat />
            </h1>
          ) : (
            ''
          )}
          <h4>{props.title}</h4>
          {props.description && (
            <p className="description">{props.description}</p>
          )}
          {props.href && (
            <p>
              <a
                href={props.slug === 'contact-us' ? void 0 : props.href}
                onClick={
                  props.slug === 'contact-us' ? () => GorgiasChat.open() : ''
                }
                target="_blank"
                rel="noreferrer"
              >
                {props.linkText}
              </a>
            </p>
          )}
        </div>
      </div>
    </SupportCardStyled>
  );
}
